<template>
  <div class="main-wrapper">
    <!-- <title-bar title="Advertise" subtitle=""></title-bar> -->
    <div
      id="advertise"
      class="gradient"
    >
      <div class="title-bar-inner">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h3 style="color:#ffffff;font-weight:bold;font-size:30px;">
                Advertise
              </h3>
              <!-- <span>{{subtitle}}</span> -->
              <!-- Breadcrumbs -->
              <!-- <nav id="breadcrumbs">
							<ul>
								<li><a >Home</a></li>
								<li>Listings</li>
							</ul>
						</nav> -->
            </div>
          </div>
        </div>
      </div>
      <div class="title-overlay" />
    </div>
    <div class="content">
      <div class="container">
        <div class="pricing-n-plan">
          <div class="col-lg-8 mx-auto pb-5 text-center">
            <p class="lead">
              Pick a plan and start your Thriggle experience today.
            </p>
          </div>
          <div class="row">
            <div class="col-lg-2" />
            <div class="col-lg-4 mb-4">
              <div class="card full-height" >
                <div class="pricing-wrapper text-center">
                  <div class="bg-primary py-5 px-4">
                    <h2 class="text-white">
                      Event
                    </h2>
                     <h5 class="text-white mb-0">Promote your Event HERE </h5>
                    <p class="text-white mb-0">ideal for 1 to 1000 locations</p>
                  </div>
                  <div class="p-4">
                    <h3 class="text-primary mb-0">
                      $5/Weekly
                    </h3>
                  </div>
                  <div class="bg-secondary p-4">
                    <ul class="pricing-listing p-0 m-0 font-weight-bold  text-left" >
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Targeted ads</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Unlimited ads per zip code</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Unlimited ad updates</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable by zip code</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable by city</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable on map</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable on the Thriggle app</li>
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Mobile Friendly </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-4 ">
                      <!-- <a
                        href="#"
                        class="btn btn-1 btn-outline-success"
                      >Free Sign-up </a> -->
                      <router-link to="sign-up" class="btn btn-1 btn-outline-success"> Free Sign-up </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="card">
                <div class="pricing-wrapper text-center">
                  <div class="bg-success py-5 px-4">
                    <h2 class="text-white">
                      Real Estate
                    </h2>
                    <h6 class="text-white mb-0">
                      Best way for Real Estate Agents to get started with Thriggle
                    </h6>
                  </div>
                  <div class="p-4">
                    <h3 class="text-success mb-0">
                      $5
                    </h3>
                  </div>
                  <div class="bg-secondary p-4">
                    <ul class="pricing-listing p-0 m-0 font-weight-bold text-left">
                      <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;   Targeted ads</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  One ad per zip code</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Unlimited ad updates</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable by zip code</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable by city</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Searchable on map</li>
                       <li><span class="text-danger font-weight-bold">✖ </span>&nbsp;  Searchable on the Thriggle app</li>
                       <li><span class="theme-color font-weight-bold">✔ </span>&nbsp;  Mobile Friendly</li>
                       <hr>
                       <li>
                         We’ve created an interface that allows real estate agents to stake a claim in their community. Rather than getting bunched together with an ocean of competitors, our platform gives you priority placement. 
                         If you’re the first real estate agent in your zip code to register, you OWN it. 
                       </li>

                    </ul>
                  </div>
                  <div>
                    <div class="p-4">
                      <!-- <a
                        href="#"
                        class="btn btn-1 btn-outline-success"
                      >  <span class="color:#000000 !important;">Free</span> Sign-up </a> -->
                        <router-link to="sign-up" class="btn btn-1 btn-outline-success"> Free Sign-up </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
	components: {
		TitleBar: TitleBar
	}
};
</script>

<style scoped>
#advertise {
    position: relative;
    padding: 70px 0;
    padding: 8rem 0;
    background: url(/static/images/advertise.png);
    background-size: cover;
    background-position: center center;
    pointer-events: none;
    color: #fff;
}
.theme-color{
  color: #5e72e4;
}
hr{
  margin-top:4px !important;
  margin-bottom:4px !important;
}
.full-height{
  height: 100%;
}
.btn-outline-success {
    color: #2dce89;
    background-color: transparent;
    background-image: none;
    border-color: #2dce89;
    position: absolute!important;
    bottom: 10px;
    left: 100px;
    left: 50% - 130px;
    /* margin: 0 auto; */
    display: block;
    margin: 0 auto;
}
.p-4{
  margin-top: 30px;
}
</style>